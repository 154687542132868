/* Header
--------------------------------------*/
.header {
  width: 100%;
  background-color: #220f27;
  padding: 0.5rem 1rem;
  z-index: 20;
  box-shadow: 0 0.1rem 0 0.1rem rgba(255, 255, 255, 0.25);
}

#wpadminbar {
  position: fixed !important;
}

.header--sticky {
  position: fixed;
  top: 0;
}

.admin-bar .header--sticky {
  top: 46px;

  @media screen and (min-width: 783px) {
    top: 32px;
  }
}

.menu--main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.menu__counter {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-color: #ed1863;
  font-size: 1rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5;
  transition: all 0.3s linear;
}

.menu-item {
  padding-left: 1rem;

  a.active {
    background-color: #ed1863 !important;
    color: #fff !important;

    .menu__counter {
      background-color: #fff !important;
      color: #ed1863 !important;
    }
  }

  &:hover { 
    a {
      text-decoration: none;
      background-color: #ed1863;

      .menu__counter {
        background-color: #fff;
        color: #ed1863;
      }
    } 
  }

  @media (hover: none) {
    &:hover {
      a {
        background-color: transparent;

        .menu__counter {
          background-color: transparent;
          color: #ed1863;
        }
      }
    }
  }

  // &:hover,
  &.current-menu-item {
    a {
      text-decoration: none;
      background-color: #ed1863;

      .menu__counter {
        background-color: #fff;
        color: #ed1863;
      }
    }
  }

  a {
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    text-decoration: none;
    transition: background-color 0.3s linear;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1.5;

    @media only screen and (max-width: $screen-md-max) {
      font-size: 1rem;
    }

    @media only screen and (max-width: $screen-sm-max) {
      font-size: 0.85rem;
    }
  }
}

.custom-logo {
  @media only screen and (max-width: $screen-sm-max) {
    width: 60px;
    height: auto;
  }
}
