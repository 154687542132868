.section--contacts {
  background-color: #b6acd1;
  color: #fff;
  font-size: 1.125rem;
  @media only screen and (max-width: rem(568px)) {
    font-size: 0.9rem;
  }

  h2 {
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: rem(568px)) {
      justify-content: center;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.contacts__column {
  overflow: hidden;
  max-width: 50%;
  flex-basis: 50%;
  border-bottom: none;
  p {
    line-height: 1.4;
  }
  @media only screen and (max-width: rem(568px)) {
    text-align: center;
    padding: 0 15px;
    margin-left: -10px;
    margin-right: -10px;
    max-width: calc(100% + 20px);
    flex-basis: calc(100% + 20px);
    justify-content: center;
    &:first-child {
      border-bottom: 2px dotted rgba(255, 255, 255, 0.35);
    }
  }
  @media only screen and (max-width: $screen-sm-max) {
    font-weight: 300;
    .contacts__heading {
      margin-bottom: rem(10px);
    }
    p {
      font-size: 0.75rem;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  i.fa-stack-1x {
    color: #9283bb;
  }
  .fa-stack-2x {
    @media only screen and (max-width: rem(568px)) {
      font-size: 1.75rem;
    }
  }
  .fa-stack {
    @media only screen and (max-width: rem(568px)) {
      width: 1.75em;
      height: 1.75em;
      line-height: 1.75em;
    }
  }
}

.copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #b6acd1;
  font-size: 1.125rem;
  @media only screen and (max-width: $screen-sm-max) {
    font-size: 9px;
    line-height: 15px;
  }
  @media only screen and (min-width: rem(568px) ) {
    .hidden-sm {
      display: none;
    }
  }
  @media only screen and (min-width: $screen-sm ) {
    justify-content: center;
  }
}

.section--copyright {
  background-color: #7763ab;
  color: #b6acd1;
  padding: 1rem 0;
  font-size: 1rem;
  .fa-linkedin {
    &:before {
      position: relative;
      top: -1px;
    }
  }
  .fa-stack-1x {
    color: #7763ab;

    @media (hover: none) {
      color: #7763ab;
    }
  }
}

.footer-bottom__social_links {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-end;
  a {
    display: block;
    margin: 0 2px;
    .fa-stack {
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      @media only screen and (max-width: rem(568px)) {
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
      }
    }

    .fa-stack-2x {
      color: #b6acd1;
      transition: color 0.3s;
      font-size: 2.5rem;
      @media only screen and (max-width: rem(568px)) {
        font-size: 1.8rem;
      }
    }

    &:hover {
      .fa-stack-2x {
        color: #ed1863;

        @media (hover: none) {
          color: #b6acd1;
        }
      }

      .fa-stack-1x {
        color: #fff;

        @media (hover: none) {
          color: #7763ab;
        }
      }
    }
    @media screen and ( max-width: $screen-sm-max ) {
      &:focus {
        .fa-stack-2x {
          color: #ed1863;

          @media (hover: none) {
            color: #b6acd1;
          }
        }

        .fa-stack-1x {
          color: #fff;

          @media (hover: none) {
            color: #7763ab;
          }
        }
      }
    }
  }
}
