/*----------------------------------------------------------------------------
 Base
----------------------------------------------------------------------------*/
/* Base
--------------------------------------*/
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Calibri", "Open Sans", "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  min-width: 320px;
  background-color: #f8f8f8;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media only screen and (max-width: 47.9375rem) {
  body {
    font-size: 0.75rem;
  }
}

/* Typography
--------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4f3177;
  margin: 1.33rem 0;
}

section h1,
section h2,
section h3,
section h4,
section h5,
section h6 {
  text-transform: uppercase;
}

section h1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

section h1:before {
  margin-right: 1rem;
}

section h1:after {
  margin-left: 1rem;
}

section h1:before, section h1:after {
  content: '';
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #4f3177;
  border-radius: 50%;
}

h1 {
  font-size: 1.6rem;
}

@media only screen and (max-width: 47.9375rem) {
  h1 {
    font-size: 1.25rem;
  }
}

h2 {
  font-size: 1.4rem;
}

@media only screen and (max-width: 47.9375rem) {
  h2 {
    font-size: 1.10rem;
  }
}

h3 {
  font-size: 1.25rem;
}

@media only screen and (max-width: 47.9375rem) {
  h3 {
    font-size: 1.05rem;
  }
}

h4 {
  font-size: 1.1rem;
}

@media only screen and (max-width: 47.9375rem) {
  h4 {
    font-size: 0.85rem;
  }
}

p {
  line-height: 1.75;
  margin-top: 0;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 47.9375rem) {
  p {
    margin-bottom: 1.3rem;
  }
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

a {
  color: #4f3177;
  text-decoration: none;
  outline: none !important;
}

a:hover {
  text-decoration: underline;
}

/*----------------------------------------------------------------------------
 Layout
----------------------------------------------------------------------------*/
.section--contacts {
  background-color: #b6acd1;
  color: #fff;
  font-size: 1.125rem;
}

@media only screen and (max-width: 35.5rem) {
  .section--contacts {
    font-size: 0.9rem;
  }
}

.section--contacts h2 {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 35.5rem) {
  .section--contacts h2 {
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.section--contacts a {
  text-decoration: none;
}

.section--contacts a:hover {
  text-decoration: underline;
}

.contacts__column {
  overflow: hidden;
  max-width: 50%;
  flex-basis: 50%;
  border-bottom: none;
}

.contacts__column p {
  line-height: 1.4;
}

@media only screen and (max-width: 35.5rem) {
  .contacts__column {
    text-align: center;
    padding: 0 15px;
    margin-left: -10px;
    margin-right: -10px;
    max-width: calc(100% + 20px);
    flex-basis: calc(100% + 20px);
    justify-content: center;
  }
  .contacts__column:first-child {
    border-bottom: 2px dotted rgba(255, 255, 255, 0.35);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .contacts__column {
    font-weight: 300;
  }
  .contacts__column .contacts__heading {
    margin-bottom: 0.625rem;
  }
  .contacts__column p {
    font-size: 0.75rem;
  }
}

.contacts__column a {
  text-decoration: none;
}

.contacts__column a:hover {
  text-decoration: underline;
}

.contacts__column i.fa-stack-1x {
  color: #9283bb;
}

@media only screen and (max-width: 35.5rem) {
  .contacts__column .fa-stack-2x {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 35.5rem) {
  .contacts__column .fa-stack {
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75em;
  }
}

.copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #b6acd1;
  font-size: 1.125rem;
}

@media only screen and (max-width: 47.9375rem) {
  .copyright {
    font-size: 9px;
    line-height: 15px;
  }
}

@media only screen and (min-width: 35.5rem) {
  .copyright .hidden-sm {
    display: none;
  }
}

@media only screen and (min-width: 48rem) {
  .copyright {
    justify-content: center;
  }
}

.section--copyright {
  background-color: #7763ab;
  color: #b6acd1;
  padding: 1rem 0;
  font-size: 1rem;
}

.section--copyright .fa-linkedin:before {
  position: relative;
  top: -1px;
}

.section--copyright .fa-stack-1x {
  color: #7763ab;
}

@media (hover: none) {
  .section--copyright .fa-stack-1x {
    color: #7763ab;
  }
}

.footer-bottom__social_links {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: flex-end;
}

.footer-bottom__social_links a {
  display: block;
  margin: 0 2px;
}

.footer-bottom__social_links a .fa-stack {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

@media only screen and (max-width: 35.5rem) {
  .footer-bottom__social_links a .fa-stack {
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
  }
}

.footer-bottom__social_links a .fa-stack-2x {
  color: #b6acd1;
  transition: color 0.3s;
  font-size: 2.5rem;
}

@media only screen and (max-width: 35.5rem) {
  .footer-bottom__social_links a .fa-stack-2x {
    font-size: 1.8rem;
  }
}

.footer-bottom__social_links a:hover .fa-stack-2x {
  color: #ed1863;
}

@media (hover: none) {
  .footer-bottom__social_links a:hover .fa-stack-2x {
    color: #b6acd1;
  }
}

.footer-bottom__social_links a:hover .fa-stack-1x {
  color: #fff;
}

@media (hover: none) {
  .footer-bottom__social_links a:hover .fa-stack-1x {
    color: #7763ab;
  }
}

@media screen and (max-width: 47.9375rem) {
  .footer-bottom__social_links a:focus .fa-stack-2x {
    color: #ed1863;
  }
}

@media screen and (max-width: 47.9375rem) and (hover: none) {
  .footer-bottom__social_links a:focus .fa-stack-2x {
    color: #b6acd1;
  }
}

@media screen and (max-width: 47.9375rem) {
  .footer-bottom__social_links a:focus .fa-stack-1x {
    color: #fff;
  }
}

@media screen and (max-width: 47.9375rem) and (hover: none) {
  .footer-bottom__social_links a:focus .fa-stack-1x {
    color: #7763ab;
  }
}

/* Job container
--------------------------------------*/
.job {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #7f7a81;
}

@media only screen and (min-width: 40rem) {
  .job {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 40rem) {
  .job {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.job__thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 40rem) {
  .job__thumbnail {
    align-items: center;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .job__thumbnail {
    width: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
}

.job__thumbnail img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.job__meta {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 47.9375rem) {
  .job__meta {
    align-items: flex-start;
    padding-left: 12px;
  }
}

.job__wrapper {
  box-shadow: 0.6px 2.9px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 73.13rem) {
  .job__wrapper {
    padding: 1rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .job__wrapper {
    box-shadow: 0.3px 1.5px 0 rgba(0, 0, 0, 0.07);
    padding: 0.7rem 0.5rem;
    border-radius: 0.5rem;
  }
}

.job__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 47.9375rem) {
  .job__title {
    font-size: 1.05rem;
  }
}

@media screen and (max-width: 47.9375rem) {
  .job__title {
    font-size: 1rem;
  }
}

.job__title a {
  color: #7f7a81;
  text-decoration: none;
  transition: color 0.3s;
  text-transform: capitalize;
}

.job__title a:hover {
  color: #ed1863;
}

@media screen and (max-width: 47.9375rem) {
  .job__title a:focus {
    color: #ed1863;
  }
}

.job__place {
  text-transform: uppercase;
}

.job__details {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.9rem;
  opacity: 0.8;
  width: 100%;
}

@media screen and (max-width: 47.9375rem) {
  .job__details {
    font-size: 0.77rem;
  }
}

@media screen and (max-width: 63.9375rem) {
  .job__details {
    margin-bottom: 0;
    text-align: left;
  }
}

.job__permalink {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 2.5rem;
  font-weight: bold;
  color: #7f7a81;
  transition: all 0.3s;
  border: 1px solid #7f7a81;
  padding: 0.75rem 2rem;
  margin-top: 0.25rem;
  max-width: 100%;
  text-decoration: none !important;
}

.job__permalink:hover {
  border-color: #ed1863;
  background-color: #ed1863;
  color: #fff;
  text-decoration: none;
}

.job__permalink--reponsive {
  color: #7f7a81;
}

@media screen and (max-width: 47.9375rem) {
  .job__permalink--reponsive .fa-2x {
    font-size: 1.1rem;
  }
}

@media (hover: none) {
  .job__permalink--reponsive:hover {
    color: #ed1863;
    text-decoration: none;
  }
}

.job__permalink--reponsive:focus, .job__permalink--reponsive:active {
  color: #ed1863;
  text-decoration: none;
}

@media screen and (max-width: 40rem) {
  .jobs {
    max-width: 320px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 40rem) {
  .job-hidden:nth-child(7) ~ .job {
    display: none;
  }
}

@media screen and (max-width: 40rem) {
  .job-hidden:nth-child(3) ~ .job {
    display: none;
  }
}

.jobs--single__banner {
  position: relative;
  overflow: hidden;
  height: 286px;
}

@media only screen and (min-width: 120rem) {
  .jobs--single__banner {
    width: auto;
    height: 564px;
  }
}

@media only screen and (min-width: 100rem) {
  .jobs--single__banner {
    width: auto;
    height: 386px;
  }
}

@media only screen and (min-width: 73.126rem) {
  .jobs--single__banner {
    width: auto;
    height: 312px;
  }
}

@media only screen and (max-width: 54rem) {
  .jobs--single__banner {
    width: auto;
    height: 224px;
  }
}

@media only screen and (max-width: 36rem) {
  .jobs--single__banner {
    width: auto;
    height: 176px;
  }
}

.jobs--single__banner .single-jobs__img {
  height: auto;
  width: 100%;
}

@media only screen and (max-width: 58rem) {
  .jobs--single__banner .single-jobs__img {
    width: 58rem;
    height: auto;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .heading--job {
    text-align: center;
  }
  .heading--job.heading--page h1 {
    margin-bottom: 0;
  }
}

.heading--job h1 {
  padding-bottom: 0.5rem !important;
}

.single-jobs__img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

/* Header
--------------------------------------*/
.header {
  width: 100%;
  background-color: #220f27;
  padding: 0.5rem 1rem;
  z-index: 20;
  box-shadow: 0 0.1rem 0 0.1rem rgba(255, 255, 255, 0.25);
}

#wpadminbar {
  position: fixed !important;
}

.header--sticky {
  position: fixed;
  top: 0;
}

.admin-bar .header--sticky {
  top: 46px;
}

@media screen and (min-width: 783px) {
  .admin-bar .header--sticky {
    top: 32px;
  }
}

.menu--main {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.menu__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.menu__counter {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  background-color: #ed1863;
  font-size: 1rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5;
  transition: all 0.3s linear;
}

.menu-item {
  padding-left: 1rem;
}

.menu-item a.active {
  background-color: #ed1863 !important;
  color: #fff !important;
}

.menu-item a.active .menu__counter {
  background-color: #fff !important;
  color: #ed1863 !important;
}

.menu-item:hover a {
  text-decoration: none;
  background-color: #ed1863;
}

.menu-item:hover a .menu__counter {
  background-color: #fff;
  color: #ed1863;
}

@media (hover: none) {
  .menu-item:hover a {
    background-color: transparent;
  }
  .menu-item:hover a .menu__counter {
    background-color: transparent;
    color: #ed1863;
  }
}

.menu-item.current-menu-item a {
  text-decoration: none;
  background-color: #ed1863;
}

.menu-item.current-menu-item a .menu__counter {
  background-color: #fff;
  color: #ed1863;
}

.menu-item a {
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  text-decoration: none;
  transition: background-color 0.3s linear;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
}

@media only screen and (max-width: 63.9375rem) {
  .menu-item a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .menu-item a {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .custom-logo {
    width: 60px;
    height: auto;
  }
}

/* Homepage
--------------------------------------*/
.section--slider {
  position: relative;
  overflow-x: hidden;
  padding: 0;
}

.slider__border--bottom {
  display: block;
  position: absolute;
  bottom: 0;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: calc(100% + 4px);
  z-index: 10;
  min-width: 1920px;
  margin-left: -2px;
  margin-right: -2px;
}

@media only screen and (max-width: 47.9375rem) {
  .slider__border--bottom {
    height: 40px;
    width: auto;
  }
}

.slider__cta {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 955px;
  z-index: 9;
}

@media only screen and (max-width: 47.9375rem) {
  .slider__cta {
    width: 500px;
  }
}

.slider-cta__img {
  display: block;
  height: auto;
  max-width: 100%;
}

@media only screen and (min-width: 120rem) {
  .slider-cta__img {
    width: 100%;
  }
}

.section--odometer {
  padding: 0 0 1.5rem;
  border-bottom: 2px solid #ececec;
}

@media only screen and (min-width: 48rem) {
  .section--odometer.container {
    width: 100%;
  }
  .section--odometer.container > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 43rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .section--odometer h4 {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .section--odometer h4 {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
  }
}

.section--jobs {
  padding-bottom: 0;
}

@media only screen and (max-width: 63.9375rem) {
  .section--jobs {
    padding-top: 0.3rem;
  }
}

.section--jobs h1 {
  margin-top: 0;
}

@media only screen and (max-width: 63.9375rem) {
  section .section-title {
    margin: 0.75rem 0;
  }
}

@media only screen and (min-width: 64rem) {
  section .section-title {
    margin: 1.5rem 0 2.5rem;
  }
}

.section--games {
  background-color: #e2e2e2;
}

@media only screen and (max-width: 63.9375rem) {
  .section--games {
    padding-top: 1.5rem;
  }
}

.section--games h1 {
  color: #ed1863;
  margin: 1.5rem 0 2rem;
}

@media only screen and (max-width: 63.9375rem) {
  .section--games h1 {
    margin: 0.3rem 0 1rem;
  }
}

.section--games h1:before, .section--games h1:after {
  background-color: #ed1863;
}

.section-about {
  padding-bottom: 0.5rem;
}

@media only screen and (max-width: 63.9375rem) {
  .section-about {
    padding-bottom: 0.2rem;
    padding-top: 10px;
  }
}

.section--cta {
  background-color: #4f3177;
}

@media only screen and (min-width: 48rem) {
  .section--cta {
    padding: 2rem 0;
  }
}

.section--cta .container h1 {
  color: #fff;
  text-transform: none;
  margin-top: 0;
}

@media only screen and (max-width: 47.9375rem) {
  .section--cta .container h1 {
    margin: 0 0 0.8rem;
    font-size: 0.94rem;
  }
}

.contacts__heading {
  text-transform: uppercase;
}

.section-about {
  background: #fff;
}

/* Grid
--------------------------------------*/
.container {
  max-width: 100%;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (min-width: 48rem) {
  .container {
    width: 43rem;
  }
}

@media only screen and (min-width: 64rem) {
  .container {
    width: 54rem;
  }
}

@media only screen and (min-width: 75rem) {
  .container {
    width: 57rem;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

section {
  padding: 1.5rem 0;
}

.visible-md,
.visible-sm,
.visible-xs {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .visible-xs {
    display: flex;
  }
}

@media only screen and (min-width: 48rem) {
  .visible-sm {
    display: flex;
  }
}

@media only screen and (min-width: 64rem) {
  .visible-md {
    display: flex;
  }
}

.hidden-md,
.hidden-sm,
.hidden-xs {
  display: flex;
}

@media only screen and (max-width: 47.9375rem) {
  .hidden-xs {
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .no-gap-xs {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 48rem) {
  .hidden-sm {
    display: none;
  }
}

@media only screen and (min-width: 64rem) {
  .hidden-md {
    display: none;
  }
}

/* Page
--------------------------------------*/
.content {
  margin-top: 3.5rem;
}

@media only screen and (max-width: 63.9375rem) {
  .content {
    margin-top: 45px;
  }
}

.section--content {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading--page {
  padding-bottom: 1.33rem;
  margin-bottom: 1.33rem;
  border-bottom: 2px solid #ebebeb;
}

.heading--page h1 {
  margin: 0;
  padding: 0;
}

.page-content--inner {
  color: #a49ba7;
}

.page-content--inner a {
  font-weight: bold;
}

.page--border {
  display: inline-block;
  padding: 1.5rem 1rem;
  box-shadow: 0.6px 2.9px 0 rgba(0, 0, 0, 0.07);
  border-radius: 1rem;
  margin: 0 0.25rem;
  background: #fff;
  font-weight: 300;
}

@media only screen and (max-width: 47.9375rem) {
  .page--border {
    padding: 1rem 0.5rem;
  }
}

/*----------------------------------------------------------------------------
 Modules
----------------------------------------------------------------------------*/
/* Buttons
--------------------------------------*/
.load-more {
  font-size: 1.1rem;
  position: relative;
  padding-top: 2rem;
}

@media only screen and (max-width: 47.9375rem) {
  .load-more.load-more--jobs {
    font-size: 0.8rem;
  }
  .load-more.load-more--jobs i {
    font-size: 1.2rem;
    margin-left: 0.6rem;
  }
}

.load-more a {
  display: inline-flex;
  padding: 0.5rem 1.5rem;
  color: #4f3177;
  text-decoration: none;
  font-weight: bold;
  border-radius: 2rem;
  background-color: #f8f8f8;
  bottom: 8px;
  position: absolute;
  transform: translate(-50%, 50%);
  left: 50%;
  right: auto;
  margin: 0 auto;
  width: 14rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.load-more a i {
  font-weight: bold;
  font-size: 1.55rem;
}

.btn {
  display: inline-block;
  padding: 0.75rem 4.5rem;
  color: #fff;
  text-decoration: none;
  transition: opacity 0.3s;
  border-radius: 1.5rem;
}

.btn:hover {
  opacity: 0.9;
  text-decoration: none;
}

.btn--pink {
  background-color: #ed1863;
  font-weight: bold;
}

.btn--transparent {
  border: 1px solid #fff;
  transition: all 0.3s;
}

.btn--transparent:hover, .btn--transparent:focus, .btn--transparent:active {
  border-color: #ed1863;
  background-color: #ed1863;
}

.gallery {
  align-items: flex-start;
}

.gallery img {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: auto;
}

@media only screen and (max-width: 47.9375rem) {
  .gallery img {
    padding: 0.2rem;
  }
}

/* Menu Responsive
--------------------------------------*/
.js-open-menu-responsive {
  display: none;
  width: 2rem;
  height: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  background-color: #ed1863;
  border-radius: 50%;
}

.js-open-menu-responsive:hover, .js-open-menu-responsive:active {
  text-decoration: none;
}

.js-open-menu-responsive .fa-chevron-up {
  margin-top: -2px;
}

@media only screen and (max-width: 47.9375rem) {
  .js-open-menu-responsive {
    display: inline-flex;
    width: 1.5625rem;
    height: 1.5625rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .menu--main {
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header__menu {
    text-align: right;
  }
}

.menu--responsive {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #220f27;
  box-shadow: 0 0.1rem 0 0.1rem rgba(255, 255, 255, 0.25);
  overflow: hidden;
}

.menu--responsive:before {
  content: '';
  position: absolute;
  left: -1px;
  right: -1px;
  top: 0;
  margin: 0 -2px;
  border-top: 2px dotted rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 30.668rem) {
  .menu--responsive .menu-item {
    padding-left: 0.5rem;
  }
  .menu--responsive .menu-item a {
    padding: 0.5rem;
  }
}

.menu--responsive .menu__list {
  justify-content: center;
}

.odometer {
  font-size: 2.5rem;
  padding: 0 !important;
  background-color: transparent !important;
  color: #ed1863;
  font-weight: bold;
}

@media only screen and (max-width: 47.9375rem) {
  .odometer {
    font-size: 1.875rem;
  }
}

.odometer-digit {
  color: #ed1863 !important;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #ebebeb 100%) !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #f6f6f6;
}

.odometer-formatting-mark {
  display: none;
}

.odometer-inside {
  border-radius: 1.5rem;
  overflow: hidden;
  border: 1px solid #e2e2e2;
  box-shadow: inset 0.15px 2px 0 rgba(119, 119, 119, 0.11);
  background-color: #f5f5f5;
}

.odometer-inside:before, .odometer-inside:after {
  content: '1';
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 1.5rem;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #ebebeb 100%) !important;
  padding: 0.1rem 0.15em;
  color: transparent;
  margin-top: 2px;
}

.odometer-inside::before {
  border-right: 1px solid #f6f6f6;
}

.odometer-inside::after {
  border-left: 1px solid #e8e8e8;
}

.odometer.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-animating-down.odometer-animating,
.odometer-ribbon-inner {
  transition-duration: 0.5s !important;
}

/* Slider
--------------------------------------*/
.section--slider {
  position: relative;
  overflow: hidden;
}

.slider {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.slider__arrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 11;
}

@media only screen and (max-width: 47.9375rem) {
  .slider__arrow img {
    width: 40px;
    height: 42px;
  }
}

.slider__arrow.slider__arrow--left {
  left: 0;
}

.slider__arrow.slider__arrow--left img {
  transform: scaleX(-1);
}

.slider__arrow.slider__arrow--right {
  right: 0;
}

#main-slider .slick-list,
#main-slider .slick-track,
#main-slider .slick-slide > div {
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 120rem) {
  #main-slider .slick-slide img {
    width: 100%;
    height: auto;
  }
}

#main-slider .slider-link {
  position: relative;
  display: block;
  height: 582px;
  overflow: hidden;
}

@media only screen and (min-width: 120rem) {
  #main-slider .slider-link {
    height: 37rem;
  }
}

@media only screen and (min-width: 160rem) {
  #main-slider .slider-link {
    height: 47rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  #main-slider .slider-link {
    height: 310px;
  }
}

@media only screen and (max-width: 40rem) {
  #main-slider .slider-link {
    height: 233px;
  }
}

#main-slider .slider-link img {
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

@media only screen and (max-width: 47.9375rem) {
  #main-slider .slider-link img {
    width: 1023px;
    height: 310px;
  }
}

@media only screen and (max-width: 40rem) {
  #main-slider .slider-link img {
    width: 768px;
    height: 233px;
  }
}

.slider.slider--games .slick-dots li {
  margin: 0 20px;
}

@media only screen and (min-width: 64rem) {
  .slider.slider--games .slick-dots li {
    margin: 0 17px;
    height: auto;
    width: auto;
  }
  .slider.slider--games .slick-dots li button {
    width: 13px;
    height: 13px;
  }
}

/* Slider
--------------------------------------*/
.slider--games .slick-dots li {
  margin: 0 0.75rem;
}

.slider--games .slick-dots li button {
  background-color: #b3b3b3;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.slider--games .slick-dots li button:before {
  display: none;
}

.slider--games .slick-dots li.slick-active button {
  background-color: #ed1863;
}

.slider--games .slick-list {
  padding-bottom: 1rem;
}

.slider__slide--game img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.slider__slide--game {
  position: relative;
  overflow: hidden;
}

.slider-game__details {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  height: 100%;
  padding: 3.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: top 0.3s;
  align-items: center;
}

@media only screen and (max-width: 47.9375rem) {
  .slider-game__details {
    padding: 1rem;
  }
}

.slider-game__details a {
  position: absolute;
  bottom: 3.5rem;
  display: block;
  padding: 0.75rem 2rem;
  left: auto;
  right: auto;
}

@media only screen and (min-width: 63.9375rem) and (max-width: 75rem) {
  .slider-game__details a {
    bottom: 2.5rem;
    padding: 0.5rem 1.5rem;
  }
}

@media only screen and (max-width: 36rem) {
  .slider-game__details a {
    bottom: 1.5rem;
    padding: 0.8rem 1.2rem;
    font-size: 13px;
    min-width: 90px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 63.9375rem) and (max-width: 75rem) {
  .slider-game__details {
    padding: 2.5rem;
    font-size: 0.85rem;
  }
}

@media only screen and (min-width: 23.45rem) and (max-width: 40rem) {
  .slider-game__details {
    padding: 2rem;
    font-size: 0.85rem;
  }
}

.slider-game__details h3 {
  border-bottom: 1px solid #fff;
  color: #fff;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 63.9375rem) and (max-width: 75rem) {
  .slider-game__details h3 {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 36rem) {
  .slider-game__details h3 {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    font-size: 0.95rem;
    margin-top: 0;
  }
}

.slider-game__details .btn-text-xs {
  display: none;
}

@media only screen and (min-width: 48rem) {
  .slider-game__details .btn-text-xs {
    display: inline-block;
  }
}

.slider-game__details .btn-text-sm {
  display: inline-block;
}

@media only screen and (min-width: 48rem) {
  .slider-game__details .btn-text-sm {
    display: none;
  }
}

@media only screen and (max-width: 36rem) {
  .slider-game__details p {
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    height: 6rem;
    overflow: hidden;
  }
}

.slider--games .slick-slide {
  position: relative;
}

.slider--games .slick-slide:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
}

.slider--games .slick-slide:nth-child(odd):after {
  background: #e2e2e2;
}

@media only screen and (min-width: 48rem) {
  .slider--games .slick-slide:after {
    background: #e2e2e2;
  }
}
