.gallery {
  align-items: flex-start;

  img {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: auto;
    @media only screen and (max-width: $screen-sm-max) {
      padding: 0.2rem;
    }
  }
}
