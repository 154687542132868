/* Page
--------------------------------------*/
.content {
  margin-top: 3.5rem;
  @media only screen and (max-width: $screen-md-max) {
    margin-top: 45px;
  }
}

.section--content {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .container {
    //box-shadow: 1px 2px 4px 2px rgba(225, 225, 225, 0.9);
    //border-radius: 1rem;
  }
}

.heading--page {
  padding-bottom: 1.33rem;
  margin-bottom: 1.33rem;
  border-bottom: 2px solid #ebebeb;

  h1 {
    margin: 0;
    padding: 0;
  }
}

.page-content--inner {
  //padding: 1.5rem;
  color: #a49ba7;

  a {
    font-weight: bold;
  }
}

.page-content--inner {

}

.page--border {
  display: inline-block;
  padding: 1.5rem 1rem;
  box-shadow: 0.6px 2.9px 0 rgba(0, 0, 0, 0.07);
  border-radius: 1rem;
  margin: 0 0.25rem;
  background: #fff;
  font-weight: 300;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 1rem 0.5rem;
  }
}
