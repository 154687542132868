.jobs--single__banner {
  position: relative;
  overflow: hidden;
  height: 286px;

  @media only screen and (min-width: 120rem) {
    width: auto;
    height: 564px;
  }

  @media only screen and (min-width: 100rem) {
    width: auto;
    height: 386px;
  }


  @media only screen and (min-width: 73.126rem) {
    width: auto;
    height: 312px;
  }

  @media only screen and (max-width: 54rem) {
    width: auto;
    height: 224px;
  }

  @media only screen and (max-width: 36rem) {
    width: auto;
    height: 176px;
  }

  .single-jobs__img {
    height: auto;
    width: 100%;

    @media only screen and (max-width: 58rem) {
      width: 58rem;
      height: auto;
    }
  }

  //.slider__border--bottom {
  //  @media only screen and (max-width: $screen-sm-max) {
  //    width: 100%;
  //    height: auto;
  //  }
  //}
}

.heading--job {
  @media only screen and (max-width: $screen-sm-max) {
    text-align: center;
    &.heading--page h1 {
      margin-bottom: 0;
    }
  }
  h1 {
    padding-bottom: 0.5rem !important;
  }
}

.job__wrapper {
   .row {
     @media only screen and (max-width: 23rem) {
     }
   }
}

.single-jobs__img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
