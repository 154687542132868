.odometer {
  font-size: 2.5rem;
  padding: 0 !important;
  background-color: transparent !important;
  color: #ed1863;
  font-weight: bold;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.875rem;
  }
}

.odometer-digit {
  color: #ed1863 !important;
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #ebebeb 100%) !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #f6f6f6;
}

.odometer-formatting-mark {
  display: none;
}

.odometer-inside {
  border-radius: 1.5rem;
  overflow: hidden;
  border: 1px solid #e2e2e2;
  box-shadow: inset 0.15px 2px 0 rgba(119, 119, 119, 0.11);
  background-color: #f5f5f5;
  &:before,
  &:after {
    content: '1';
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 1.5rem;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0%, #ebebeb 100%) !important;
    padding: 0.1rem 0.15em;
    color: transparent;
    margin-top: 2px;
  }

  &::before {
    border-right: 1px solid #f6f6f6;
  }

  &::after {
    border-left: 1px solid #e8e8e8;
  }
}

.odometer.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-animating-down.odometer-animating,
.odometer-ribbon-inner {
  transition-duration: 0.5s !important
}
