/* Slider
--------------------------------------*/
.section--slider {
  position: relative;
  overflow: hidden;
}

.slider {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.slider__arrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 11;

  img {
    @media only screen and (max-width: $screen-sm-max) {
      width: 40px;
      height: 42px;
    }
  }

  &.slider__arrow--left {
    left: 0;

    img {
      transform: scaleX(-1);
    }
  }

  &.slider__arrow--right {
    right: 0;
  }
}

#main-slider {
  .slick-list,
  .slick-track,
  .slick-slide > div {
    position: relative;
    height: 100%;
  }

  .slick-slide {
    img {
      @media only screen and (min-width: 120rem) {
        width: 100%;
        height: auto;
      }
    }
  }

  .slider-link {
    position: relative;
    display: block;
    height: 582px;
    overflow: hidden;

    @media only screen and (min-width: 120rem) {
      height: 37rem;
    }

    @media only screen and (min-width: 160rem) {
      height: 47rem;
    }

    @media only screen and (max-width: $screen-sm-max) {
      height: 310px;
    }

    @media only screen and (max-width: 40rem) {
      height: 233px;
    }

    img {
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;

      @media only screen and (max-width: $screen-sm-max) {
        width: 1023px;
        height: 310px;
      }

      @media only screen and (max-width: 40rem) {
        width: 768px;
        height: 233px;
      }
    }
  }
}

.slider.slider--games .slick-dots {
  li {
    margin: 0 20px;
    @media only screen and (min-width: $screen-md) {
      margin: 0 17px;
      height: auto;
      width: auto;
      button {
        width: 13px;
        height: 13px;
      }
    }
  }
}
