/* Grid
--------------------------------------*/
.container {
  max-width: 100%;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @media only screen and (min-width: $screen-sm) {
    width: 43rem;
  }

  @media only screen and (min-width: $screen-md) {
    width: 54rem;
  }


  @media only screen and (min-width: $screen-lg) {
    width: 57rem;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

section {
  padding: 1.5rem 0;
}

.visible-md,
.visible-sm,
.visible-xs {
  display: none;
}

.visible-xs {
  @media only screen and (max-width: $screen-sm-max) {
    display: flex;
  }
}

.visible-sm {
  @media only screen and (min-width: $screen-sm) {
    display: flex;
  }
}

.visible-md {
  @media only screen and (min-width: $screen-md) {
    display: flex;
  }
}

.hidden-md,
.hidden-sm,
.hidden-xs {
  display: flex;
}

.hidden-xs {
  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.no-gap-xs {
  @media only screen and (max-width: $screen-sm-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.hidden-sm {
  @media only screen and (min-width: $screen-sm) {
    display: none;
  }
}

.hidden-md {
  @media only screen and (min-width: $screen-md) {
    display: none;
  }
}
