/* Menu Responsive
--------------------------------------*/
.js-open-menu-responsive {
  display: none;
  width: 2rem;
  height: 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  background-color: #ed1863;
  border-radius: 50%;

  &:hover,
  &:active {
    text-decoration: none;
  }
  .fa-chevron-up {
    margin-top: -2px;
  }

  @media only screen and (max-width: $screen-sm-max) {
    display: inline-flex;
    width: rem(25px);
    height: rem(25px);
  }

}

.menu--main {
  @media only screen and (max-width: $screen-sm-max) {
    display: none;
  }
}

.header__menu {
  @media only screen and (max-width: $screen-sm-max) {
    text-align: right;
  }
}

.menu--responsive {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: #220f27;
  box-shadow: 0 0.1rem 0 0.1rem rgba(255, 255, 255, 0.25);
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    top: 0;
    margin: 0 -2px;
    border-top: 2px dotted rgba(255, 255, 255, 0.2);
  }

  .menu-item {
    @media only screen and (max-width: 30.668rem) {
      padding-left: 0.5rem;

      a {
        padding: 0.5rem;
      }
    }

    // a {
    //   &:hover {
    //     background-color: transparent !important;
    //   }
    // }

    // &:hover {
    //   a {
    //     background-color: transparent !important;
    //   }
    // }
  }

  .menu__list {
    justify-content: center;
  }
}
