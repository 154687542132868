// Typography
//------------------------------------//
$base_font_family: 'Calibri', 'Open Sans', 'Helvetica Neue', sans-serif;
$base_font_size: 16px;
$line_height: 1.2;
$paragraph_margin: 1rem;

// Grid
//------------------------------------//
$container_width: 1024px;

$screen-sm-max: (rem(768px - 1));
$screen-sm: (rem(768px));
$screen-md-max: (rem(1024px - 1));
$screen-md: (rem(1024px));
$screen-lg: rem(1200px);
