/* Job container
--------------------------------------*/
.job {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  color: #7f7a81;

  @media only screen and (min-width: 40rem) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  @media only screen and (max-width: 40rem) {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.job__thumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 40rem) {
    align-items: center;
  }

  @media only screen and (max-width: $screen-sm-max) {
    width: 20%;
    flex-basis: 20%;
    max-width: 20%;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.job__meta {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: $screen-sm-max) {
    align-items: flex-start;
    padding-left: 12px;
  }
}

.job__wrapper {
  box-shadow: 0.6px 2.9px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;

  @media only screen and (max-width: 73.13rem) {
    padding: 1rem;
  }

  @media only screen and (max-width: $screen-sm-max) {
    //box-shadow: 1px 1px 2px 1px rgba(225, 225, 225, 0.9);
    box-shadow: 0.3px 1.5px 0 rgba(0, 0, 0, 0.07);
    padding: 0.7rem 0.5rem;
    border-radius: 0.5rem;
  }
}

.job__title {
  margin-top: 0;
  margin-bottom: 0.5rem;

  @media screen and ( max-width: $screen-sm-max ) {
    font-size: 1.05rem;
  }

  @media screen and ( max-width: $screen-sm-max ) {
    font-size: 1rem;
  }

  a {
    color: #7f7a81;
    text-decoration: none;
    transition: color 0.3s;
    text-transform: capitalize;

    &:hover {
      color: #ed1863;
    }
    @media screen and ( max-width: $screen-sm-max ) {
      &:focus {
        color: #ed1863;
      }
    }
  }
}

.job__place {
  text-transform: uppercase;
}

.job__details {
  margin-bottom: 0.5rem;
  font-weight: 400;
  font-size: 0.9rem;
  opacity: 0.8;
  width: 100%;

  @media screen and ( max-width: $screen-sm-max ) {
    font-size: 0.77rem;
  }

  @media screen and ( max-width: $screen-md-max ) {
    margin-bottom: 0;
    text-align: left;
  }
}

.job__permalink {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border-radius: 2.5rem;
  font-weight: bold;
  color: #7f7a81;
  transition: all 0.3s;
  border: 1px solid #7f7a81;;
  padding: 0.75rem 2rem;
  margin-top: 0.25rem;
  max-width: 100%;
  text-decoration: none !important;

  &:hover {
    border-color: #ed1863;
    background-color: #ed1863;
    color: #fff;
    text-decoration: none;
  }
}

.job__permalink--reponsive {
  color: #7f7a81;

  .fa-2x {
    @media screen and ( max-width: $screen-sm-max ) {
      font-size: 1.1rem;
    }
  }

  @media (hover: none) {
    &:hover {
      color: #ed1863;
      text-decoration: none;
    }
  }

  &:focus,
  &:active {
    color: #ed1863;
    text-decoration: none;
  }
  
  @media screen and ( max-width: $screen-sm-max ) {
    
  }
}

.jobs {
  @media screen and ( max-width: 40rem ) {
    max-width: 320px;
    margin: 0 auto;
  }
}

@media screen and ( min-width: 40rem) {
  .job-hidden:nth-child(7) ~ .job {
    display: none;
  }
}

@media screen and ( max-width: 40rem) {
  .job-hidden:nth-child(3) ~ .job {
    display: none;
  }
}
