body {
  @media only screen and (max-width: $screen-sm-max) {
    font-size: 0.75rem;
  }
}

/* Typography
--------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4f3177;
  margin: 1.33rem 0;
}

section {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
  }

  h1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:before {
      margin-right: 1rem;
    }

    &:after {
      margin-left: 1rem;
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background-color: #4f3177;
      border-radius: 50%;
    }
  }
}

h1 {
  font-size: 1.6rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.25rem;
  }
}

h2 {
  font-size: 1.4rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.10rem;
  }
}

h3 {
  font-size: 1.25rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 1.05rem;
  }
}

h4 {
  font-size: 1.1rem;

  @media only screen and (max-width: $screen-sm-max) {
    font-size: 0.85rem;
  }
}

p {
  line-height: 1.75;
  margin-top: 0;
  margin-bottom: 2rem;
  @media only screen and (max-width: $screen-sm-max) {
    margin-bottom: 1.3rem;
  }
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

a {
  color: #4f3177;
  text-decoration: none;
  outline: none !important;

  &:hover {
    text-decoration: underline;
  }
}
