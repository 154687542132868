/* Base
--------------------------------------*/
html {
  box-sizing: border-box;
  font-size: $base_font_size;
  font-family: $base_font_family;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  min-width: 320px;
  background-color: #f8f8f8;
}

*, *:before, *:after {
  box-sizing: inherit;
}
