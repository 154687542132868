/* Slider
--------------------------------------*/
.slider--games {
  .slick-dots {
    li {
      margin: 0 0.75rem;

      button {
        background-color: #b3b3b3;
        transition: background-color 0.3s;
        border-radius: 50%;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          background-color: #ed1863;
        }
      }
    }
  }

  .slick-list {
    padding-bottom: 1rem;
  }
}

.slider__slide--game {
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.slider__slide--game {
  position: relative;
  overflow: hidden;

  &:hover {
    .slider-game__details {
      //top: 0;
    }
  }
}

.slider-game__details {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  height: 100%;
  padding: 3.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: top 0.3s;
  align-items: center;
  @media only screen and (max-width: $screen-sm-max) {
    padding: 1rem;
  }

  a {
    position: absolute;
    bottom: 3.5rem;
    display: block;
    padding: 0.75rem 2rem;
    left: auto;
    right: auto;

    @media only screen and (min-width: 63.9375rem) and (max-width: $screen-lg) {
      bottom: 2.5rem;
      padding: 0.5rem 1.5rem;
    }

    @media only screen and (max-width: 36rem) {
      bottom: 1.5rem;
      padding: 0.8rem 1.2rem;
      font-size: 13px;
      min-width: 90px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media only screen and (min-width: 63.9375rem) and (max-width: $screen-lg) {
    padding: 2.5rem;
    font-size: 0.85rem;
  }

  @media only screen and (min-width: 23.45rem)  and (max-width: 40rem) {
    padding: 2rem;
    font-size: 0.85rem;
  }

  h3 {
    border-bottom: 1px solid #fff;
    color: #fff;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 63.9375rem) and (max-width: $screen-lg) {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

    @media only screen and (max-width: 36rem) {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      font-size: 0.95rem;
      margin-top: 0;
    }
  }
  .btn-text-xs {
    display: none;
    @media only screen and (min-width: $screen-sm) {
      display: inline-block;
    }
  }
  .btn-text-sm {
    display: inline-block;
    @media only screen and (min-width: $screen-sm) {
      display: none;
    }
  }
  p {
    @media only screen and (max-width: 36rem) {
      font-size: 0.75rem;
      line-height: 1rem;
      text-align: center;
      height: 6rem;
      overflow: hidden;
    }
  }
}

.slider--games .slick-slide {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
  }
  &:nth-child(odd) {
    &:after {
      background: #e2e2e2;
    }
  }
  @media only screen and (min-width: $screen-sm) {
    &:after {
      background: #e2e2e2;
    }
  }
}
