/* Buttons
--------------------------------------*/
.load-more {
  font-size: 1.1rem;
  position: relative;
  padding-top: 2rem;
  &.load-more--jobs {
    @media only screen and (max-width: $screen-sm-max) {
      font-size: 0.8rem;
      i {
        font-size: 1.2rem;
        margin-left: 0.6rem;
      }
    }
  }
  a {
    display: inline-flex;
    padding: 0.5rem 1.5rem;
    color: #4f3177;
    text-decoration: none;
    font-weight: bold;
    border-radius: 2rem;
    background-color: #f8f8f8;
    bottom: 8px;
    position: absolute;
    transform: translate(-50%, 50%);
    left: 50%;
    right: auto;
    margin: 0 auto;
    width: 14rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i {
      font-weight: bold;
      font-size: 1.55rem;
    }
  }
}

.btn {
  display: inline-block;
  padding: 0.75rem 4.5rem;
  color: #fff;
  text-decoration: none;
  transition: opacity 0.3s;
  border-radius: 1.5rem;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

.btn--pink {
  background-color: #ed1863;
  font-weight: bold;
}

.btn--transparent {
  border: 1px solid #fff;
  transition: all 0.3s;

  &:hover,
  &:focus,
  &:active {
    border-color: #ed1863;
    background-color: #ed1863;
  }
}
