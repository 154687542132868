/* Homepage
--------------------------------------*/
.section--slider {
  position: relative;
  overflow-x: hidden;
  padding: 0;
}

.slider__border--bottom {
  display: block;
  position: absolute;
  bottom: 0;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: calc(100% + 4px);
  z-index: 10;
  min-width: 1920px;
  margin-left: -2px;
  margin-right: -2px;

  @media only screen and (max-width: $screen-sm-max) {
    height: 40px;
    width: auto;
  }
}

.slider__cta {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 955px;
  z-index: 9;

  @media only screen and (max-width: $screen-sm-max) {
    width: 500px;
  }

}

.slider-cta__img {
  display: block;
  height: auto;
  max-width: 100%;

  @media only screen and (min-width: 120rem) {
    width: 100%;
  }
}

.section--odometer {
  padding: 0 0 1.5rem;
  border-bottom: 2px solid #ececec;
  @media only screen and (min-width: $screen-sm) {
    &.container {
      width: 100%;
      > div {
        margin-left: auto;
        margin-right: auto;
        max-width: 43rem;
      }
    }
  }

  h4 {
    @media only screen and (max-width: $screen-sm-max) {
      font-size: 0.75rem;
    }
    @media only screen and (max-width: $screen-sm-max) {
      font-size: 0.85rem;
      margin-bottom: 0.5rem;
    }
  }
}

.section--jobs {
  padding-bottom: 0;
  @media only screen and (max-width: $screen-md-max) {
    padding-top: 0.3rem;
  }

  h1 {
    margin-top: 0;
  }
}

section .section-title {
  @media only screen and (max-width: $screen-md-max) {
    margin: 0.75rem 0;
  }
  @media only screen and (min-width: $screen-md) {
    margin: 1.5rem 0 2.5rem;
  }
}

.section--games {
  background-color: #e2e2e2;
  @media only screen and (max-width: $screen-md-max) {
    padding-top: 1.5rem;
  }

  h1 {
    color: #ed1863;
    margin: 1.5rem 0 2rem;
    @media only screen and (max-width: $screen-md-max) {
      margin: 0.3rem 0 1rem;
    }
    &:before,
    &:after {
      background-color: #ed1863;
    }
  }
}

.section-about {
  padding-bottom: 0.5rem;
  @media only screen and (max-width: $screen-md-max) {
    padding-bottom: 0.2rem;
    padding-top: 10px;
  }
}

.section--cta {
  background-color: #4f3177;
  @media only screen and (min-width: $screen-sm) {
    padding: 2rem 0;
  }
  .container h1 {
    color: #fff;
    text-transform: none;
    margin-top: 0;
    @media only screen and (max-width: $screen-sm-max) {
      margin: 0 0 0.8rem;
      font-size: 0.94rem;
    }
  }
}

.contacts__heading {
  text-transform: uppercase;
}

.section-about {
  background: #fff;
}

